// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-color-dark {
	color: black !important;
}
body > input {
	color: black !important;
}

* > P {
	margin: 0;
}

body {
	color: black;
    background-color: white;
	overflow: auto !important;
}
/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */

input[type="number"] {
	-moz-appearance: textfield;
}
`, "",{"version":3,"sources":["webpack://./src/app.css"],"names":[],"mappings":"AAAA;CACC,uBAAuB;AACxB;AACA;CACC,uBAAuB;AACxB;;AAEA;CACC,SAAS;AACV;;AAEA;CACC,YAAY;IACT,uBAAuB;CAC1B,yBAAyB;AAC1B;AACA,gCAAgC;;AAEhC;;CAEC,wBAAwB;CACxB,SAAS;AACV;;AAEA,YAAY;;AAEZ;CACC,0BAA0B;AAC3B","sourcesContent":[".text-color-dark {\n\tcolor: black !important;\n}\nbody > input {\n\tcolor: black !important;\n}\n\n* > P {\n\tmargin: 0;\n}\n\nbody {\n\tcolor: black;\n    background-color: white;\n\toverflow: auto !important;\n}\n/* Chrome, Safari, Edge, Opera */\n\ninput::-webkit-outer-spin-button,\ninput::-webkit-inner-spin-button {\n\t-webkit-appearance: none;\n\tmargin: 0;\n}\n\n/* Firefox */\n\ninput[type=\"number\"] {\n\t-moz-appearance: textfield;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
