import {jwtDecode} from "jwt-decode";
import React, { Suspense } from "react";
import { useSelector } from "react-redux";
import {
  Route,
  BrowserRouter,
  Routes,
  Navigate,
} from "react-router-dom";
// import Login from "./views/pages/login/Login"
import { icons } from "./assets/icons";
import { LoadingProvider } from "./providers/LoadingProvider";
import { showError } from "./util/error";
import "./scss/style.scss";
import "./app.css";

React.icons = icons;
const Login = React.lazy(() => import("./Pages/login/Login"));
const TheLayout = React.lazy(() => import("./Layout/TheLayout"));

function App() {
  const employee = useSelector((state) => state.employee);
  const token = localStorage.getItem("token");
  const tokenDecode = token ? jwtDecode(token) : "";
  let expired = false;
  if (new Date(tokenDecode.exp).getTime() <= Date.now()) {
    expired = true;
  }
  if (expired) {
    localStorage.clear();
    showError("Your login session has been expired!");
  }

  const loading = (
    <div className="pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse"></div>
      ...Loading
    </div>
  );

  const PrivateRoute = () => {
    if (employee.length > 0 || localStorage.getItem("token")) {
      return <TheLayout />;
    }
    return <Navigate to="/login" />
  };

  return (
    <div>
      <BrowserRouter basename="/">
        <LoadingProvider>
            <Suspense fallback={loading}>
              <Routes>
                <Route exact path="/login" name="Login" element={<Login/>} />
                <Route path="/" element={<PrivateRoute/>} />
              </Routes>
            </Suspense>
        </LoadingProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
